.Emoji {
	display: block;
	margin-top: 40px;
	font-size: 3.4rem;
}

.Loading {
	width: 85px;
}

.WaitMessage {
	font-weight: 400;
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.QuoteMessage {
	font-style: italic;
	max-width: 250px;
   font-weight: 300;
   font-size: 1.05rem;
	margin: auto;
}