.Intro{
  margin-bottom: 20px;
}

.ShareHint {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px dashed #ccc;
}

.ButtonTree > a {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  color: #363636;
  border: none;
  background-color: #f8f8f8;
  position: relative;
  font-weight: 400;
  border-radius: 3px;
  font-size: 1rem;
  display: flex;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
}

.ButtonTree > a:hover {
  font-weight: 400;
  background-color: #f8f8f877;
}

.ButtonTree > a > div {
  width: 30px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 10px;
}

.ButtonTree > a > div > svg {
  width: 100% !important;
  height: 100%;
}

.ButtonTree {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}
