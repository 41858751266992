.Icon {
	width: 100%;
	background: none;
	position: relative;
	font-weight: 300;
}

.IconContainer > svg {
   width: 100px;
   height: 100px;
}

.TextContainer {
	margin-top: 30px;
	line-height: 1.8rem;
}
.IconContainer {
	width: 100px;
	margin: auto;
}

.Backdrop {
	/* background: rgba(243, 237, 209, 0.5); */
	background: rgba(146, 146, 146, 0.15);
	position: fixed;
	z-index: -10;
	width: 0px;
	height: 0px;
	left: 0px;
	top: 0px;
	border-radius: 99999999999999px;
	-webkit-animation: loading 2000ms ease-in-out both;
	animation: loading 2000ms ease-in-out both;
}

@-webkit-keyframes loading {
	0% {
		width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-right: 0px;
	}
	100% {
		width: 2000px;
      height: 2000px;
      margin-left: -1000px;
      margin-top: -1000px;
	}
}
@keyframes loading {
	0% {
		width: 0px;
		height: 0px;
	}
	100% {
		width: 2000px;
      height: 2000px;
      margin-left: -1000px;
      margin-top: -1000px;
	}
}
