.Hitw {
  text-align: left;
}

.HitwSteps {
  margin: 10px 0;
  font-weight: 300;
  padding-inline-start: 20px;
}
.HitwSteps li {
  padding-left: 15px;
  margin-bottom: 10px;
}
