.Review {
  width: 100%;
  max-width: 365px;
  margin: 0px auto;
  padding: 0rem 1rem 45px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.ReviewContent {
  display: block;
  margin: auto;
  height: fit-content;
  width: 100%;
}

.PoweredBy {
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9rem;
  padding: 10px;
  color: #838383;
  font-weight: 300;
  -webkit-animation: fade-in 400ms linear both;
          animation: fade-in 400ms linear both;
}
.PoweredBy > span {
  color: #0e3721;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

/* ----------------------------------------------
 * ANIMATIONS fade-in
 * ---------------------------------------- */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}