.Button {
   cursor: pointer;
   padding: 10px 55px;
   border-radius: 1000px;
   border: none;
   color: #fff;
   background: #c0c0c0;
}

.MainButton {
   background-color: #4c8bf5;
}

.Button:hover,
.MainButton:hover {
   box-shadow: inset 0 0 0 1000px rgba(0,0,0,.05);
}

.SquareButton {
   border-radius: 3px;
}

.LinkButton {
   outline: none;
   color: #4c8bf5;
   cursor: pointer;
   background: none;
   border-radius: 0;
   padding: 10px 0 3px;
   border-bottom: 1px dashed;
}
.LinkButton:hover {
   color: #4c8bf5bb;
   border-color: #4c8bf5bb;
}

.ButtonSize__small {
   font-size: 0.8em;
}

@media (max-width:349px){
   .Button{
      padding: 10px 50px;
   }
}
