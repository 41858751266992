.Review {
  width: 100%;
  max-width: 350px;
  margin: 0px auto;
  padding: 0rem 1rem;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.ReviewContent {
  display: block;
  margin: auto;
  height: fit-content;
}

.PoweredBy {
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9rem;
  padding: 10px;
  color: #838383;
  font-weight: 300;
  -webkit-animation: fade-in 400ms linear both;
          animation: fade-in 400ms linear both;
}
.PoweredBy > span {
  color: #0e3721;
  font-weight: 400;
  text-decoration: underline;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  color: var(--dark);
  -webkit-animation: fade-in 400ms linear both;
          animation: fade-in 400ms linear both;
}
.Container p {
  max-width: 500px;
  text-align: center;
}
.Title{
  margin: 0;
  font-weight: 700;
  font-size: 1.4em;
}
.Subtitle {
  margin: 10px 0 0;
  color: var(--dark);
  font-weight: 300;
  font-size: 1.3em;
}
.Intro {
  color: var(--grey);
  font-size: 1em;
  margin-top: 15px;
  font-weight: 300;
}

@media (max-width: 700px) {
  .Container {
    padding: 0 15px;
    text-align: center;
  }
  .Container p {
    max-width: 90%;
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

/* ----------------------------------------------
 * ANIMATIONS fade-in
 * ---------------------------------------- */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}