.InputContainer {
  margin: 10px 0;
}

.TextBox {
  font-family: var(--main-font);
  border: solid 2px rgb(210, 210, 210);
  color: var(--dark);
  outline: none;
  width: 100%;
  font-size: 1.1rem;
  padding: 14px;
  box-sizing: border-box;
  /* border-radius: var(--border-radius); */
  -webkit-appearance: none;
  -o-transition: border-color 0.2s ease-out;
  -ms-transition: border-color 0.2s ease-out;
  -moz-transition: border-color 0.2s ease-out;
  -webkit-transition: border-color 0.2s ease-out;
  transition: border-color 0.2s ease-out;
}

.InputError{
  border: solid 2px var(--danger) !important;
}

.TextBox:focus {
  border: solid 2px var(--dark-main-color);
}

.TextBox::-webkit-input-placeholder {
  /* Edge */
  color: rgb(170, 170, 170);
  font-size: 1rem;
}

.TextBox:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgb(170, 170, 170);
  font-size: 1rem;
}

.TextBox::placeholder {
  color: rgb(170, 170, 170);
  font-size: 1rem;
}

.TextBoxBottomBordered {
  border: none;
  padding: 8px 0;
  border-radius: 0;
  border-bottom: solid 2px rgb(210, 210, 210);
}

.TextBoxBottomBordered:focus {
  border-radius: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border: solid 2px var(--dark-main-color);
}

.TextBoxBottomBordered.InputError {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: solid 1px var(--danger) !important;
}

.TextBoxBottomBordered::-webkit-input-placeholder,
.TextBoxBottomBordered:-ms-input-placeholder,
.TextBoxBottomBordered::placeholder {
  color: rgb(170, 170, 170);
  font-size: 0.7rem;
}

.TextWarning {
  text-align: left;
  font-size: 0.7em;
  margin: 4px 0;
  color: var(--danger);
  font-weight: 300;
}
