.TextArea{
   width: 100%;
   border: none;
   outline: none;
   resize:vertical;
   min-height: 150px;
   border-radius: 2px;
   margin: 20px 0 30px;
   background-color: var(--light-grey);
}
.InputText {
   outline: none;
   border-top: none;
   border-left: none;
   border-right: none;
}
.InputText:focus {
   border-top: none;
   border-left: none;
   border-right: none;
   border-bottom: solid 2px var(--dark-main-color);
}
.SubmitButton:disabled {
   color: var(--grey);
   background-color: var(--light-grey);
}
.SubmitButton {
   color: #fff;
   border-radius: 5px;
   background-color: var(--main-color);
}