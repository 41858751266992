.Rating {
  color: #cacaca;
}
.Star {
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.8em;
  transition: color 0.2s ease-out;
	margin-top: 30px;
	margin-bottom: 30px;
}
.Star:hover {
  cursor: pointer;
}
.Selected  {
  color: #eeb820;
}
.Unselected  {
  color: #cacaca !important;
}
.Disabled:hover {
  cursor: default;
}
