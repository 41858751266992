.ButtonsContainer {
	margin-top: 35px;
}
.ButtonsContainer > button {
	cursor: pointer;
	margin-right: 10px;
}

.ButtonsContainer > button:hover {
	box-shadow: inset 0 0 0 1000px rgba(0,0,0,.05);
}

.ButtonsContainerDecorated > button {
	width: 100%;
	margin-top: 20px;
	padding: 20px 10px;
	color: #363636;
	border: none;
	background-color: #f8f8f8;
	position: relative;
	font-weight: 400;
	border-radius: 3px;
	text-align: left;
	padding-left: 60px;
	font-size: 1rem;
	margin-right: 0;
}

.ButtonsContainerDecorated > button > div {
	position: absolute;
	left: 8px;
	width: 40px;
	height: 40px;
	padding: 13px 0px 0px 13px;
	box-sizing: border-box;
	top: 8px;
	border-radius: 1000px;
	overflow: hidden;
}

.ButtonsContainer > button:last-of-type {
	margin-right: 0px;
}

.ButtonsContainerFlex {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.ButtonsContainerFlex > button {
	margin: 10px 0;
}
.ButtonsContainerFlex > button:last-of-type {
	margin-top: 0px;
}