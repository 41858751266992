.ThumbsButton {
	padding: 20px 25px;
	font-size: 1.75rem;
	border-radius: 1000px;
	border: none;
	background: #c0c0c0;
	color: #fff;
}

.ThumbsButton:focus {
	outline: none;
}

.MainButton {
	color: #fff;
   background-color: #4c8bf5;
   margin-left: 30px !important;
}