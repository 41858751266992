:root {
  --main-color: #0e3721;
  --light-main-color: #d1f3e7;
  --dark-main-color: #1a5e6d;
  --dark-bg: #35333a;
  --darker-bg: #29282e;
  --light: #fff;
  --dark: #35333a;
  --grey: #828282;
  --light-grey: #f1f1f1;
  --danger: #f85959;
  --success: #098e49;
  --light-success: #f0fff7;
  --border-radius: 5px;
  --muli: 'Muli', sans-serif;
  --box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04),
    0 12px 48px 0 rgba(0, 0, 0, 0.08);
  --main-font: 'DM Sans', sans-serif;
  --animation-speed: 750;
  --back-button-size: 20px;
  height: 100%;
  --font-size: 1.15rem;
}

.App {
	font-family: var(--main-font);
	color: #3a3939;
	box-sizing: border-box;
	font-size: var(--font-size);
	font-weight: 400;
	height: calc(var(--vh, 1vh) * 100);
}

button {
  font-family: var(--main-font);
  font-size: 1.05rem;
  font-weight: 300;
}
