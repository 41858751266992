.Content {
   text-align: center;
	-webkit-animation: fade-in 400ms linear both;
           animation: fade-in 400ms linear both;
}

.FadeOut{
   -webkit-animation: fade-out 150ms linear both;
           animation: fade-out 150ms linear both;
}

.TextContainer {
	line-height: 1.7rem;
}

.TitleContainer {
	font-size: 1.1em;
	font-weight: 500;
	margin-bottom: 1.2em;
}

.TextLight {
	font-weight: 300;
}

.TextError {
	margin: 5px 0;
	font-size: 0.9em;
	font-weight: 300;
	color: var(--danger);
}

/* ----------------------------------------------
 * ANIMATIONS fade-in
 * ---------------------------------------- */
@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


/* ----------------------------------------------
 * ANIMATIONS fade-out
 * ---------------------------------------- */
 @-webkit-keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
