.Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  color: var(--dark);
  -webkit-animation: fade-in 400ms linear both;
          animation: fade-in 400ms linear both;
}
.Container p {
  max-width: 500px;
  text-align: center;
}
.Title{
  margin: 0;
  font-weight: 700;
  font-size: 1.4em;
}
.Subtitle {
  margin: 10px 0 0;
  color: var(--dark);
  font-weight: 300;
  font-size: 1.3em;
}
.Intro {
  color: var(--grey);
  font-size: 1em;
  margin-top: 15px;
  font-weight: 300;
}

@media (max-width: 700px) {
  .Container {
    padding: 0 15px;
    text-align: center;
  }
  .Container p {
    max-width: 90%;
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

/* ----------------------------------------------
 * ANIMATIONS fade-in
 * ---------------------------------------- */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}